const initialState = {
    buttonDisabled: true,
    resetButtonDisabled: true,
    betButtonDisabled: true,
    nextRoundButtonDisabled: true,
    chipsToWinnerButtonDisabled: true,
    speakerMutedState: true,
    selectedPlayer: 0,
    sceneLoaded: false,
    logMsg: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BUTTON_DISABLED_STATE':
            return { ...state, buttonDisabled: action.payload }

        case 'SET_RESET_BUTTON_DISABLED_STATE':
            return { ...state, resetButtonDisabled: action.payload }

        case 'SET_BET_BUTTON_DISABLED_STATE':
            return { ...state, betButtonDisabled: action.payload }

        case 'SET_NEXT_ROUND_BUTTON_DISABLED_STATE':
            return { ...state, nextRoundButtonDisabled: action.payload }

        case 'SET_CHISP_TO_WINNER_BUTTON_DISABLED_STATE':
            return { ...state, chipsToWinnerButtonDisabled: action.payload }

        case 'SET_SELECTED_PLAYER_STATE':
            console.log('action.payload', action.payload)
            return { ...state, selectedPlayer: action.payload }

        case 'SET_UPDATED_LOG' :
            const newList = [...state.logMsg, ...action.payload]
            console.log('log newList', newList)
            return { ...state, logMsg: newList }

        case 'SET_MUTED_STATE' :
            return { ...state, speakerMutedState: action.payload }

        case 'CLEAR_LOG' :
            return { ...state, logMsg: action.payload }

        case 'SET_SCENE_LOADED_STATE' :
            return { ...state, sceneLoaded: action.payload }

        default:
            return state
    }
}
export default reducer