const type = {    
    JOIN_GAME: `:Joined the table`,
    DEAL_CARDS: `:Deal Cards`, // should change this, must come from BE
    RESET_TABLE: `:Reset Table`,
    SHUFFLE_CARDS: `:Shuffle Cards`,
    ROTATE_DEALER: `:Rotate Dealer`,
    BUY_CHIPS: `:Buy 2 Chips`,
    NEXT_ROUND: `:Bet chips to pot`,
    CHIPS_TO_WINNER: `:Won chips`,
    SET_POV: `:Choose Player` ,
    BET_HANDLER: ':Bet 2 Chips',
    LEAVE_GAME:':Left the game'
}

export default type