import React, { useEffect } from 'react'
import { Assets } from '../../shared/utils/assets'
import Text from '../../shared/components/Text'
import { connect, useSelector } from 'react-redux'

const player = [
    { img: Assets.position1 },
    { img: Assets.position2 },
    { img: Assets.position3 },
    { img: Assets.position4 },
    { img: Assets.position5 }]

const PointOfView = ({ sendMessage }) => {
    const InitialPointOfView = useSelector(state => state.gameReducer.player?.position || 1)
    const SceneLoaded = useSelector(state => state.appReducer.sceneLoaded)
    const players = useSelector(state => state.gameReducer.players) || []
    useEffect(() => {
        InitialPointOfView && SceneLoaded && sendMessage('GAMEMANAGER', 'SetPov', InitialPointOfView)
    }, [InitialPointOfView, SceneLoaded])

    return (
        <div className="h-100 w-100">
            <div className="w-100 fw-semibold d-flex align-items-center justify-content-center mt-2"
                 style={{ height: '15%', color: '#ec9700' }}><Text content={'Player Details'}/>
            </div>
            <div className="w-100 d-flex flex-column align-items-center justify-content-center text-light fw-semibold"
                 style={{ height: '85%', backgroundColor: '#0c233d' }}>
                {
                    players
                        .sort((a, b) => a.label - b.label)
                        .map((play, index) => {
                            return (
                                <div key={index}
                                     className={`d-flex align-items-center justify-content-center w-100 ${play.position === InitialPointOfView
                                         ? 'bg-faded-black '
                                         : ''}`}
                                     style={{ height: '20%', cursor: 'default' }}>
                                    <div
                                        className="d-flex align-items-center justify-content-center h-100 border-bottom border-secondary border-2"
                                        style={{ width: '15%' }}>
                                        <img src={player[index].img} alt="pov-1" className="w-50 h-50"/>
                                    </div>
                                    <div
                                        className="d-flex align-items-center justify-content-start ps-3 h-100 border-bottom border-secondary border-2 "
                                        style={{ width: '65%', color: play.color }}
                                    >
                                        Seat {play.label} : {players[index]?.slot === 'OPEN' ? 'Waiting' : players[index]?.name}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-start ps-3 h-100"
                                         style={{ width: '20%' }}></div>
                                </div>
                            )
                        })
                }
            </div>
        </div>
    )
}

export default connect((state) => {
    return {
        gameReducer: state.gameReducer
    }
})
(PointOfView)
