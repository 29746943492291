import React from 'react'

const Text = ({className, style = {}, color, fontSize, fontWeight, content}) => {
    return (
        <text
            className={className}
            style={{...style, ...style}}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
        >
            {content}
        </text>
    )
}

export default Text