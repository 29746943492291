import React from 'react'
import Button from '../../shared/components/Button'
import Text from '../../shared/components/Text'
import '../static/css/sidebar.scss'
import PlayerSelect from './PlayerSelect'
import { useSelector } from 'react-redux'

const Sidebar = ({
    sendMessage,
    dealCards = () => {},
    resetTable = () => {},
    shuffleCards = () => {},
    rotateDealer = () => {},
    buyChips = () => {},
    nextRound = () => {},
    chipsToWinner = () => {},
    setPOV = () => {}
}) => {

    const buttonDisabled = useSelector(state => state.appReducer.buttonDisabled)
    const nextRoundButtonDisabled = useSelector(state => state.appReducer.nextRoundButtonDisabled)
    const chipsToWinnerButtonDisabled = useSelector(state => state.appReducer.chipsToWinnerButtonDisabled)
    const ResetButtonDisabled = useSelector(state => state.appReducer.resetButtonDisabled)
    const players = useSelector(state => state.gameReducer.players)
    const selectedPlayer = useSelector(state => state.appReducer.selectedPlayer)
    let isBuyChipsDisabled = false
    if (selectedPlayer === 0) {
        players.map((player) => {
            if (isBuyChipsDisabled) {
                return
            } else if (player.chips >= 99) {
                isBuyChipsDisabled = true
            }
        })
    } else {
        players.map((player) => {
            if (player.position === selectedPlayer && player.chips >= 99) {
                isBuyChipsDisabled = true
            }
        })
    }
    if (buttonDisabled) {
        isBuyChipsDisabled = true
    }
    return (
        <div className="d-flex flex-column justify-content-end w-100 h-100">
            <div className="d-flex align-items-center justify-content-evenly flex-column w-100"
            >
                <Button disabled={buttonDisabled || ResetButtonDisabled} color={'#cf5060'} onClick={resetTable}>
                    <Text content={'Reset Table'}/>
                </Button>
                <Button disabled={buttonDisabled} color={'#881FF4'} onClick={shuffleCards}>
                    <Text content={'Shuffle Cards'}/>
                </Button>
                <Button disabled={buttonDisabled} color={'#ffba03'} onClick={rotateDealer}>
                    <Text content={'Rotate Dealer'}/>
                </Button>
            </div>
            <div className="w-100 d-xxl-flex d-sm-none" style={{ height: '4%' }}></div>
            <div className="d-flex align-items-center justify-content-center w-100" style={{ height: '30%' }}>
                <PlayerSelect setPOV={setPOV} sendMessage={sendMessage} disabled={buttonDisabled}/>
            </div>
            <div className="w-100 d-xxl-flex d-sm-none" style={{ height: '4%' }}></div>
            <div className="d-flex align-items-center justify-content-evenly flex-column w-100">
                <Button disabled={buttonDisabled} color={'#41b5a7'} onClick={dealCards}>
                    <Text content={'Deal Cards'}/>
                </Button>
                <Button disabled={isBuyChipsDisabled} color={'#84de45'} onClick={buyChips}>
                    <Text content={'Buy Chips'}/>
                </Button>
                <Button disabled={nextRoundButtonDisabled || buttonDisabled} color={'#84de45'} onClick={nextRound}>
                    <Text content={'Next Round'}/>
                </Button>
                <Button disabled={chipsToWinnerButtonDisabled || buttonDisabled} color={'#f0e7cf'} onClick={chipsToWinner}>
                    <Text content={'Chips to Winner'}/>
                </Button>
            </div>
        </div>
    )
}

export default Sidebar