import Navbar from './app/components/Navbar'
import { UnityScene } from './app/components/Unity'
import PointOfView from './app/components/PointOfView'
import Logs from './app/components/Logs'
import Sidebar from './app/components/Sidebar'
import { useUnityContext } from 'react-unity-webgl'
import React, { createRef, useCallback, useEffect, useRef } from 'react'
import TextField from './shared/components/TextField'
import Socket from './shared/utils/socket'
import { setButtonDisabledState, setSceneLoadedState } from './app/action'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { isWebGL2Supported } from 'webgl-detector'
import { setCards, setChips, setPlayersUpdate, setWebsocketMeg } from './game/action'
import logo from './shared/static/img/logo.png'

const App = () => {
    const inputRef = createRef()
    const socketRef = useRef()
    const {
        unityProvider,
        isLoaded,
        loadingProgression,
        sendMessage,
        addEventListener,
        removeEventListener
    } = useUnityContext({
        loaderUrl: 'unitybuild/BUILDS.loader.js',
        dataUrl: 'unitybuild/BUILDS.data',
        frameworkUrl: 'unitybuild/BUILDS.framework.js',
        codeUrl: 'unitybuild/BUILDS.wasm',
        companyName: 'Palatar',
        productName: 'Poker',
        productVersion: '0.3.0'
    })

    const webSocketMeg = useSelector(state => state.gameReducer.webSocketMeg)
    const players = useSelector(state => state.gameReducer.players)

    const loadingPercentage = Math.round(loadingProgression * 100)

    const handleSceneLoaded = useCallback(() => {
        console.log('Unity scene loaded')
        dispatch(setSceneLoadedState(true))
        dispatch(setButtonDisabledState(false))
    }, [])

    useEffect(() => {
        addEventListener('SceneLoaded', handleSceneLoaded)
        return () => {
            removeEventListener('SceneLoaded', handleSceneLoaded)
        }
    }, [addEventListener, removeEventListener, handleSceneLoaded])

    const dispatch = useDispatch()

    const handleButtonDisabledState = () => {
        dispatch(setButtonDisabledState(true))
    }

    const handleButtonEnabledState = () => {
        dispatch(setButtonDisabledState(false))
    }

    const handleDealCardUpdate = () => {
        const i = webSocketMeg.player
        console.log(' players  players', players)
        console.log(' players web socket', webSocketMeg.message.cards)
        players[i - 1] = webSocketMeg.message.cards[players[i - 1].label][0]
        dispatch(setPlayersUpdate(players))
        dispatch(setButtonDisabledState(false))
        if (webSocketMeg && webSocketMeg.player < 6) {
            sendMessage('GAMEMANAGER', 'DealCards', JSON.stringify({
                rounds: webSocketMeg.rounds, targetPov: players[webSocketMeg.player].position
            }))
            dispatch(setWebsocketMeg({
                message: webSocketMeg.message,
                player: webSocketMeg.player + 1,
                rounds: webSocketMeg.rounds
            }))
        }
    }

    useEffect(() => {
        console.log('log in component did mount')
        return () => {
            console.log('log in component unmount')
            disconnectClient()
        }
    }, [])

    useEffect(() => {
        const startingEvents = [
            'ResetCardsStarted',
            'ClearChipsStarted',
            'ClearChipsBetStarted',
            'ClearChipsPotStarted',
            'ShuffleCardsStarted',
            'WalkOutStarted',
            'WalkInStarted',
            'DistributeChipsStarted',
            'MoveChipsInStarted',
            'MoveChipsToWinningPovStarted',
            'EnableLookAtStarted',
            'DisableLookAtStarted',
            'SpeakStarted'
        ]
        const endingEvents = [
            'AnimationOver',
            'ClearChipsEnded',
            'ClearChipsBetEnded',
            'ClearChipsPotEnded',
            'ResetAndShuffleEnded',
            'ShuffleCardsEnded',
            'WalkInEnded',
            'DistributeChipsEnded',
            'MoveChipsInEnded',
            'MoveChipsToWinningPovEnded',
            'EnableLookAtEnded',
            'DisableLookAtEnded',
            'SpeakEnded'
        ]
        startingEvents.forEach((event) => {
            addEventListener(event, handleButtonDisabledState)
        })
        addEventListener('AnimationOver', handleDealCardUpdate)
        endingEvents.forEach((event) => {
            addEventListener(event, handleButtonEnabledState)
        })
        return () => {
            startingEvents.forEach((event) => {
                removeEventListener(event, handleButtonDisabledState)
            })
            endingEvents.forEach((event) => {
                removeEventListener(event, handleButtonEnabledState)
            })
        }
    }, [
        addEventListener,
        removeEventListener,
        webSocketMeg
    ])
    const dealCards = () => {
        socketRef.current.dealCards()
    }

    const resetTable = () => {
        socketRef.current.resetTable()
        inputRef.current.clearText()
        dispatch(setCards())
        dispatch(setChips())
        dispatch(setButtonDisabledState(true))
    }

    const shuffleCards = () => {
        socketRef.current.shuffleCards()
    }

    const rotateDealer = () => {
        socketRef.current.rotateDealer()
    }

    const buyChips = () => {
        socketRef.current.buyChips()
    }

    const nextRound = () => {
        socketRef.current.nextRound()
    }

    const chipsToWinner = () => {
        socketRef.current.chipsToWinner()
    }

    const speak = (message) => {
        socketRef.current.speak(message)
    }

    const leaveGame = () => {
        socketRef.current.leaveGame()
    }

    const setVideo = (req) => {
        socketRef.current.setVideo(req)
    }

    if (!isWebGL2Supported())
        return (
            <div
                className="d-flex flex-column align-items-center justify-content-center vw-100 vh-100 bg-dark-blue text-light-blue">
                <img src={logo} alt="Palatar Logo"/>
                <h1 className="text-center fw-bolder m-5">WebGL2 is not supported by your device</h1>
            </div>
        )
    if (isMobile) return (
        <div
            className="d-flex flex-column align-items-center justify-content-center vw-100 vh-100 bg-dark-blue text-light-blue">
            <img src={logo} alt="Palatar Logo"/>
            <h1 className="text-center fw-bolder m-5">Palatar Cards is not currently supported on mobile devices</h1>
        </div>
    )

    const setPOV = (selectedPlayer) => {
        socketRef.current.setPOV(selectedPlayer)
    }

    const disconnectClient = () => {
        socketRef.current.disconnectClient()
    }

    const betClickHandler = () => {
        socketRef.current.betClickHandler()
    }

    const newGame = () => {
        socketRef.current.newGame()
    }

    return (
        <div className="vh-100 vw-100 d-flex flex-column bg-dark-blue">
            <Socket
                sendMessage={sendMessage}
                ref={socketRef}
                addEventListener={addEventListener}
                disconnectClient={disconnectClient}/>

            <Navbar leaveGame={leaveGame} newGame={newGame}/>

            <div className="d-flex w-100" style={{ height: 'calc(100% - 75px)' }}>
                <div className="d-flex flex-column w-15 mb-4">
                    <Sidebar
                        dealCards={dealCards}
                        resetTable={resetTable}
                        shuffleCards={shuffleCards}
                        rotateDealer={rotateDealer}
                        buyChips={buyChips}
                        nextRound={nextRound}
                        chipsToWinner={chipsToWinner}
                        loadingPercentage={loadingPercentage}
                        addEventListener={addEventListener}
                        removeEventListener={removeEventListener}
                        sendMessage={sendMessage}
                        setPOV={setPOV}/>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center h-100"
                     style={{ width: '65%' }}>
                    <div className="w-100 pt-1" style={{ height: '85%' }}>
                        <div className="unity-container">
                            <UnityScene
                                unityProvider={unityProvider}
                                sceneLoaded={isLoaded}
                                betClickHandler={betClickHandler}
                                setVideo={setVideo}
                            />
                        </div>
                    </div>

                    <div className="w-100 h-15">
                        <TextField
                            onClick={speak}
                            ref={inputRef}
                        />
                    </div>
                </div>

                <div className="w-20 d-flex flex-column">
                    <div className="h-40 w-100">
                        <PointOfView sendMessage={sendMessage}/>
                    </div>

                    <div className="h-60 w-100 mb-4">
                        <Logs/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
