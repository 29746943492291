import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import appReducer from './app/reducer'
import gameReducer from './game/reducer'
import videoReducer from './video/reducer'

export default configureStore({
    reducer: combineReducers({ appReducer, gameReducer, videoReducer }),
    middleware: [promise, thunk, createLogger()],
})
