import React, { Component } from 'react'
import WebSocket from 'isomorphic-ws'
import { connect } from 'react-redux'
import { keyBy } from 'lodash'
import {
    setCards,
    setChips,
    setChipsInPot,
    setMaximumPlayerExceeded,
    setPlayersUpdate,
    setUserDetails,
    setWebsocketMeg
} from '../../game/action'
import {
    clearLog,
    setBetButtonDisabledState,
    setButtonDisabledState,
    setChipsToWinnerButtonDisabledState, setNextRoundButtonDisabledState,
    setResetButtonDisabledState,
    setSelectedPlayerState,
    setUpdateLog
} from '../../app/action'
import { clearId, clearPlayer, getId, getPlayer, storeId, storePlayer } from './stroge-util'
import { faker } from '@faker-js/faker'
import { setAllVideos } from '../../video/action'

const defaultCoins = 2
const allRoundCardDealTime = 4000
const oneCardDealTime = 800

class Socket extends Component {
    componentWillUnmount() {
        this.props.disconnectClient()
    }

    componentDidMount() {
        const self = this

        setTimeout(() => {
            const ws = new WebSocket(process.env.REACT_APP_SOCKET_URL || 'ws://localhost:8081')

            ws.onopen = function open() {
                const localPlayer = getPlayer()
                const storeID = getId()
                console.log('local player', localPlayer)
                if (localPlayer) {
                    const player = JSON.parse(localPlayer)
                    const req = {
                        type: 'RECONNECT_CLIENT',
                        position: player.position,
                        room: player.room,
                        id: storeID,
                        player: localPlayer
                    }
                    ws.send(JSON.stringify(req))
                } else {
                    const req = {
                        type: 'REQUEST_POSITION'
                    }
                    ws.send(JSON.stringify(req))
                }
            }

            ws.onclose = function close() {
                console.log('log disconnected')
            }

            ws.onmessage = function incoming(event) {
                console.log('log message received', event.data)
                const data = event.data
                const msg = JSON.parse(data)
                console.log('log msg received', msg)

                if (msg.type === 'REQUEST_POSITION') {
                    self.requestPosition(msg.message, ws)
                } else if (msg.type === 'JOIN_GAME') {
                    console.log('log msg join game', msg)
                    self.joinGameUnity(msg)
                } else if (msg.type === 'DEAL_CARDS') {
                    self.dealCardsUnity(msg)
                } else if (msg.type === 'RESET_TABLE') {
                    self.resetTableUnity(msg)
                } else if (msg.type === 'SHUFFLE_CARDS') {
                    self.shuffleCardUnity()
                } else if (msg.type === 'ROTATE_DEALER') {
                    self.rotateDealerUnity(msg.switchAvatar)
                } else if (msg.type === 'BUY_CHIPS') {
                    self.buyChipsUnity(msg, msg.chipsCount)
                } else if (msg.type === 'NEXT_ROUND') {
                    self.nextRoundUnity()
                } else if (msg.type === 'CHIPS_TO_WINNER') {
                    self.chipsToWinnerUnity(msg.targetPlayer)
                } else if (msg.type === 'LOG_UPDATED') {
                    self.logUpdated(msg)
                } else if (msg.type === 'TEXT_TO_SPEECH') {
                    console.log('log message 3', msg)
                    self.textToSpeechUnity(msg.message)
                } else if (msg.type === 'SET_POV') {
                    self.setPOVUnity(msg)
                } else if (msg.type === 'PLAYERS_UPDATED') {
                    self.setPlayers(msg)
                } else if (msg.type === 'RECONNECT_CLIENT') {
                    self.reconnectClient(msg)
                } else if (msg.type === 'LEAVE_GAME') {

                } else if (msg.type === 'RECONNECT_CLIENT_FAILED') {
                    clearPlayer()
                    clearId()
                    const req = {
                        type: 'REQUEST_POSITION'
                    }
                    ws.send(JSON.stringify(req))
                } else if (msg.type === 'BET_HANDLER') {
                    self.betClickHandlerUnity(msg)
                } else if (msg.type === 'INSUFFICIENT_CARDS') {
                    self.insufficientMessageUnity(msg.message)
                } else if (msg.type === 'MAX_PLAYER_EXCEEDED') {
                    self.setMaxPlayerError()
                } else if (msg.type === 'VIDEO_ADDED') {
                    self.updateVideo(msg.videos)
                } else if (msg.type === 'NEW_GAME') {
                    self.newGameUnity(msg, ws)
                }
            }

            window.ws = ws
            console.log('log ws active on windows')
        }, 5000)
    }

    updateVideo(videos = []) {
        const obj = keyBy(videos, 'position')
        this.props.dispatch(setAllVideos(obj))
    }

    newGameUnity(msg, ws) {
        const { sendMessage } = this.props

        setTimeout(() => {
            sendMessage('GAMEMANAGER', 'ResetCards', 0)
            sendMessage('GAMEMANAGER', 'ClearChips', 0)
            sendMessage('GAMEMANAGER', 'ClearChipsBet', 0)
            sendMessage('GAMEMANAGER', 'ClearChipsPot', 0)
        }, 2000)

        this.props.dispatch(setSelectedPlayerState(msg.POV))
        this.props.dispatch(clearLog(msg.log))
        this.props.dispatch(setPlayersUpdate(msg.players))
        const req = {
            type: 'REQUEST_POSITION'
        }
        ws.send(JSON.stringify(req))
    }

    requestPosition(msg, ws) {
        this.props.dispatch(setUserDetails(msg))
        const name = faker.name.firstName()
        const obj = {
            'type': 'JOIN_GAME', 'position': msg.position, 'name': name, initiator: msg.position
        }
        ws.send(JSON.stringify(obj))
    }

    setMaxPlayerError() {
        this.props.dispatch(setMaximumPlayerExceeded(true))
    }

    async reconnectClient(msg) {
        console.log('log test reconnect')
        await this.props.dispatch(setUserDetails(msg.player[0]))
        await this.props.dispatch(setSelectedPlayerState(msg.POV))
        await this.props.dispatch(setMaximumPlayerExceeded(false))
        storePlayer(msg.player[0])
        storeId(msg.id)
        const dealCards = msg.logs.DEAL_CARDS ? msg.logs.DEAL_CARDS.length : 0
        let timeoutSec = 0
        if (dealCards > 0) {
            const cards = msg.cards
            let minimum = 55
            for (let i = 1; i < 6; i++) {
                if (cards[i][0].cards.length < minimum) {
                    minimum = cards[i][0].cards.length
                }
            }
            this.dealCardsUnity({ cards: msg.cards, position: 0 }, minimum)
            timeoutSec = allRoundCardDealTime * minimum
            for (let i = 1; i < 6; i++) {
                const diff = cards[i][0].cards.length - minimum
                if (diff !== 0) {
                    setTimeout(() => {
                        this.dealCardsUnity({ cards: msg.cards, position: i }, diff)
                    }, timeoutSec)
                    timeoutSec = diff * oneCardDealTime
                }
            }
        }

        const buyChips = msg.logs.BUY_CHIPS ? msg.logs.BUY_CHIPS.length : 0
        if (buyChips > 0) {
            let minimum = 55000
            const cards = msg.cards
            for (let i = 1; i < 6; i++) {
                if ((cards[i][0].chips + cards[i][0].pot + cards[i][0].mid) < minimum) {
                    minimum = cards[i][0].chips + cards[i][0].pot + cards[i][0].mid
                }
            }
            this.buyChipsUnity({ cards: msg.cards, position: 0 }, minimum)
            timeoutSec = timeoutSec + 100
            for (let i = 1; i < 6; i++) {
                const diff = (cards[i][0].chips + cards[i][0].pot + cards[i][0].mid) - minimum
                if (diff !== 0) {
                    setTimeout(() => {
                        this.buyChipsUnity({ cards: msg.cards, position: i }, diff)
                    }, timeoutSec)
                    timeoutSec = timeoutSec + 100
                }
            }
        }
        const setPOVLength = msg.logs.SET_POV ? msg.logs.SET_POV.length : 0
        if (setPOVLength > 0) {
            const setPOV = msg.logs.SET_POV
            this.setPOVUnity({ POV: setPOV[setPOVLength - 1].POV })
        }

        const switchAvatarLength = msg.logs.ROTATE_DEALER ? msg.logs.ROTATE_DEALER.length : 0
        if (switchAvatarLength > 0) {
            const switchAvatar = msg.logs.ROTATE_DEALER
            this.rotateDealerUnity(switchAvatar[switchAvatarLength - 1].switchAvatar)
        }

        const betHandlerLength = msg.logs.BET_HANDLER ? msg.logs.BET_HANDLER.length : 0
        if (betHandlerLength > 0) {
            const betHandler = msg.logs.BET_HANDLER
            for (let i = 0; i < betHandler.length; i++) {
                setTimeout(() => {
                    this.betClickHandlerUnity(betHandler[i])
                }, 700)
            }
        }
        const nextRoundLength = msg.logs.NEXT_ROUND ? msg.logs.NEXT_ROUND.length : 0
        if (nextRoundLength > 0) {
            const nextRound = msg.logs.NEXT_ROUND
            for (let i = 0; i < nextRound.length; i++) {
                setTimeout(() => {
                    this.nextRoundUnity()
                }, 1400)
            }
        }

        const chipsToWinnerLength = msg.logs.CHIPS_TO_WINNER ? msg.logs.CHIPS_TO_WINNER.length : 0
        if (chipsToWinnerLength > 0) {
            const chipsToWinner = msg.logs.CHIPS_TO_WINNER
            for (let i = 0; i < chipsToWinner.length; i++) {
                setTimeout(() => {
                    this.chipsToWinnerUnity()
                }, 2800)
            }
        }
    }

    async joinGameUnity(msg) {
        console.log('msg', msg)
        console.log('log test join game')
        await this.props.dispatch(setUserDetails(msg.player[0]))
        await this.props.dispatch(setSelectedPlayerState(msg.POV))
        await this.props.dispatch(setMaximumPlayerExceeded(false))
        storePlayer(msg.player[0])
        storeId(msg.id)
        const dealCards = msg.logs.DEAL_CARDS ? msg.logs.DEAL_CARDS.length : 0
        let timeoutSec = 0
        if (dealCards > 0) {
            const cards = msg.cards
            let minimum = 55
            for (let i = 1; i < 6; i++) {
                if (cards[i][0].cards.length < minimum) {
                    minimum = cards[i][0].cards.length
                }
            }
            this.dealCardsUnity({ cards: msg.cards, position: 0 }, minimum)
            timeoutSec = allRoundCardDealTime * minimum
            for (let i = 1; i < 6; i++) {
                const diff = cards[i][0].cards.length - minimum
                if (diff !== 0) {
                    setTimeout(() => {
                        this.dealCardsUnity({ cards: msg.cards, position: i }, diff)
                    }, timeoutSec)
                    timeoutSec = diff * oneCardDealTime
                }
            }
        }

        const buyChips = msg.logs.BUY_CHIPS ? msg.logs.BUY_CHIPS.length : 0
        if (buyChips > 0) {
            let minimum = 55000
            const cards = msg.cards
            for (let i = 1; i < 6; i++) {
                if ((cards[i][0].chips + cards[i][0].pot + cards[i][0].mid) < minimum) {
                    minimum = cards[i][0].chips + cards[i][0].pot + cards[i][0].mid
                }
            }
            this.buyChipsUnity({ cards: msg.cards, position: 0 }, minimum)
            timeoutSec = timeoutSec + 100
            for (let i = 1; i < 6; i++) {
                const diff = (cards[i][0].chips + cards[i][0].pot + cards[i][0].mid) - minimum
                if (diff !== 0) {
                    setTimeout(() => {
                        this.buyChipsUnity({ cards: msg.cards, position: i }, diff)
                    }, timeoutSec)
                    timeoutSec = timeoutSec + 100
                }
            }
        }
        const setPOVLength = msg.logs.SET_POV ? msg.logs.SET_POV.length : 0
        if (setPOVLength > 0) {
            const setPOV = msg.logs.SET_POV
            this.setPOVUnity({ POV: setPOV[setPOVLength - 1].POV })
        }
        const switchAvatarLength = msg.logs.ROTATE_DEALER ? msg.logs.ROTATE_DEALER.length : 0
        if (switchAvatarLength > 0) {
            const switchAvatar = msg.logs.ROTATE_DEALER
            this.rotateDealerUnity(switchAvatar[switchAvatarLength - 1].switchAvatar)
        }

        const betHandlerLength = msg.logs.BET_HANDLER ? msg.logs.BET_HANDLER.length : 0
        if (betHandlerLength > 0) {
            const betHandler = msg.logs.BET_HANDLER
            for (let i = 0; i < betHandler.length; i++) {
                setTimeout(() => {
                    this.betClickHandlerUnity(betHandler[i])
                }, 700)
            }
        }

        const nextRoundLength = msg.logs.NEXT_ROUND ? msg.logs.NEXT_ROUND.length : 0
        if (nextRoundLength > 0) {
            const nextRound = msg.logs.NEXT_ROUND
            for (let i = 0; i < nextRound.length; i++) {
                setTimeout(() => {
                    this.nextRoundUnity()
                }, 1400)
            }
        }

        const chipsToWinnerLength = msg.logs.CHIPS_TO_WINNER ? msg.logs.CHIPS_TO_WINNER.length : 0
        console.log('chipsToWinnerLength', chipsToWinnerLength)
        if (chipsToWinnerLength > 0) {
            const chipsToWinner = msg.logs.CHIPS_TO_WINNER
            for (let i = 0; i < chipsToWinner.length; i++) {
                setTimeout(() => {
                    this.chipsToWinnerUnity()
                }, 2800)
            }
        }
    }

    dealCardsUnity(message, rounds = 1) {
        const { speakerMutedState } = this.props.appReducer
        const { sendMessage } = this.props
        if (message.position === 0) {
            let { players } = this.props.gameReducer
            sendMessage('GAMEMANAGER', 'DealCards', JSON.stringify({
                rounds: rounds, targetPov: players[0].position
            }))
            this.props.dispatch(setWebsocketMeg({
                message: message,
                player: 1,
                rounds: rounds
            }))
            this.props.dispatch(setButtonDisabledState(true))

        } else {
            sendMessage('GAMEMANAGER', 'DealCards', JSON.stringify({
                rounds: rounds, targetPov: message.position
            }))
            this.props.dispatch(setPlayersUpdate(message.players))
        }

        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', 'Deal Cards')
        }
        // this.props.dispatch(setWebsocketMeg(message))
    }

    betClickHandlerUnity(msg) {
        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer
        this.props.dispatch(setNextRoundButtonDisabledState(false))
        console.log('betClickHandlerUnity', msg)
        sendMessage('GAMEMANAGER', 'MoveChipsToMid', JSON.stringify({
            chips: msg.chipsCount,
            targetPov: msg.position
        }))
        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', 'Bet')
        }
    }

    chipsToWinnerUnity(targetPlayer) {
        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer
        const { chipsInPot } = this.props.gameReducer
        console.log('log chipsInPot', chipsInPot)
        this.props.dispatch(setChipsInPot())
        this.props.dispatch(setChipsToWinnerButtonDisabledState(true))

        sendMessage('GAMEMANAGER', 'MoveChipsToWinningPov', targetPlayer)
        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', 'Distributing chips to winner')
        }
    }

    textToSpeechUnity(text) {
        console.log('log text', text)

        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer

        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', text)
        }
    }

    insufficientMessageUnity(text) {
        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer
        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', text)
        }
        this.props.dispatch(setButtonDisabledState(false))
    }

    nextRoundUnity() {
        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer
        this.props.dispatch(setChipsToWinnerButtonDisabledState(false))
        this.props.dispatch(setBetButtonDisabledState(true))
        this.props.dispatch(setNextRoundButtonDisabledState(true))

        sendMessage('GAMEMANAGER', 'MoveChipsIn', 0)
        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', 'Next Round')
        }
    }

    buyChipsUnity(message, chips = defaultCoins) {
        this.props.dispatch(setButtonDisabledState(true))
        this.props.dispatch(setResetButtonDisabledState(false))
        this.props.dispatch(setBetButtonDisabledState(false))

        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer

        sendMessage('GAMEMANAGER', 'DistributeChips', JSON.stringify({
            chips: chips, targetPov: message.position
        }))
        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', 'Distribute Chips')
        }
    }

    rotateDealerUnity(switchAvatar) {
        this.props.dispatch(setCards())
        this.props.dispatch(setSelectedPlayerState())
        this.props.dispatch(setChipsInPot())

        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer
        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', 'Change Dealer')
        }
        sendMessage('GAMEMANAGER', 'DisableLookAt')
        setTimeout(() => {
            sendMessage('GAMEMANAGER', 'ResetCards', 0)
            sendMessage('GAMEMANAGER', 'ClearChips', 0)
            sendMessage('GAMEMANAGER', 'ClearChipsBet', 0)
            sendMessage('GAMEMANAGER', 'ClearChipsPot', 0)
        }, 2000)
        setTimeout(() => {
            sendMessage('GAMEMANAGER', 'SwitchAvatar', switchAvatar)
        }, 4000)
    }

    shuffleCardUnity() {
        this.props.dispatch(setCards())
        this.props.dispatch(setSelectedPlayerState(0))

        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer
        sendMessage('GAMEMANAGER', 'DisableLookAt')
        setTimeout(() => {
            sendMessage('GAMEMANAGER', 'ResetCards', 0)
            sendMessage('GAMEMANAGER', 'ShuffleCards', 0)
        }, 2000)
        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', 'Shuffle Cards')
        }
    }

    resetTableUnity(msg) {
        this.props.dispatch(setCards())
        this.props.dispatch(setChips())
        this.props.dispatch(setSelectedPlayerState(msg.POV))
        this.props.dispatch(setChipsInPot())
        this.props.dispatch(setBetButtonDisabledState(true))
        this.props.dispatch(setNextRoundButtonDisabledState(true))
        this.props.dispatch(setChipsToWinnerButtonDisabledState(true))

        const { sendMessage } = this.props
        const { speakerMutedState } = this.props.appReducer

        sendMessage('GAMEMANAGER', 'DisableLookAt')
        setTimeout(() => {
            sendMessage('GAMEMANAGER', 'ResetCards', 0)
            sendMessage('GAMEMANAGER', 'ClearChips', 0)
            sendMessage('GAMEMANAGER', 'ClearChipsBet', 0)
            sendMessage('GAMEMANAGER', 'ClearChipsPot', 0)
        }, 2000)
        if (!speakerMutedState) {
            sendMessage('GAMEMANAGER', 'SpeakWithAnimation', 'Reset Table')
        }
    }

    setPlayers(msg) {
        this.props.dispatch(setPlayersUpdate(msg.players))
        // console.log('log cards', msg.players[0].cards)
    }

    logUpdated(msg) {
        this.props.dispatch(setUpdateLog(msg.log))
    }

    setPOVUnity(meg) {
        const { sendMessage } = this.props
        this.props.dispatch(setSelectedPlayerState(meg.POV))
        if (meg.POV === 0) {
            sendMessage('GAMEMANAGER', 'DisableLookAt')
        } else {
            sendMessage('GAMEMANAGER', 'EnableLookAt', `P${meg.POV}`)
        }
    }

    setPOV(selectedPlayer) {
        const { player } = this.props.gameReducer
        const req = {
            type: 'SET_POV', POV: selectedPlayer, initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    disconnectClient() {
        const { player } = this.props.gameReducer
        const req = {
            type: 'DISCONNECT_CLIENT', position: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    dealCards() {
        const { selectedPlayer } = this.props.appReducer
        const { player } = this.props.gameReducer
        // this.props.dispatch(setButtonDisabledState(true))
        // this.props.dispatch(setResetButtonDisabledState(false))
        const req = {
            type: 'DEAL_CARDS', position: selectedPlayer, initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    resetTable() {
        const { player } = this.props.gameReducer
        this.props.dispatch(setButtonDisabledState(true))
        const req = {
            type: 'RESET_TABLE',
            initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    betClickHandler() {
        const { player } = this.props.gameReducer
        const req = {
            type: 'BET_HANDLER',
            initiator: player.position,
            position: player.position,
            chips: defaultCoins
        }

        window.ws.send(JSON.stringify(req))
    }

    shuffleCards() {
        const { player } = this.props.gameReducer
        this.props.dispatch(setButtonDisabledState(true))
        const req = {
            type: 'SHUFFLE_CARDS',
            initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    rotateDealer() {
        const { player } = this.props.gameReducer
        this.props.dispatch(setButtonDisabledState(true))
        const req = {
            type: 'ROTATE_DEALER',
            initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    buyChips() {
        const { selectedPlayer } = this.props.appReducer
        const { player } = this.props.gameReducer
        this.props.dispatch(setButtonDisabledState(true))
        this.props.dispatch(setResetButtonDisabledState(false))
        const req = {
            type: 'BUY_CHIPS', position: selectedPlayer, chips: defaultCoins, initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    nextRound() {
        const { player } = this.props.gameReducer
        const req = {
            type: 'NEXT_ROUND',
            initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    chipsToWinner() {
        const { player } = this.props.gameReducer
        this.props.dispatch(setButtonDisabledState(true))
        this.props.dispatch(setResetButtonDisabledState(false))
        const req = {
            type: 'CHIPS_TO_WINNER',
            initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    speak(message) {
        const { player } = this.props.gameReducer
        const req = {
            type: 'TEXT_TO_SPEECH', message, initiator: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    leaveGame() {
        const { player } = this.props.gameReducer
        const req = {
            type: 'LEAVE_GAME', position: player.position
        }

        window.ws.send(JSON.stringify(req))
    }

    setVideo(req) {
        // setTimeout(() => {
        const player = JSON.parse(sessionStorage.getItem('player'))
        const req2 = { ...req, position: player.position }

        window.ws.send(JSON.stringify({ ...req, position: player.position }))
        // }, 5000)

    }

    newGame() {
        const { player } = this.props.gameReducer
        const req = {
            type: 'NEW_GAME', position: player.position
        }
        console.log('log new game unity call')
        window.ws.send(JSON.stringify(req))
    }

    render() {
        return (<div/>)
    }
}

export default connect((state) => {
    return {
        appReducer: state.appReducer,
        gameReducer: state.gameReducer
    }
}, null, null, { forwardRef: true })(Socket)
