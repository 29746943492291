import React, { useLayoutEffect, useRef } from 'react'
import '../static/css/logs.scss'
import moment from 'moment'
import Text from '../../shared/components/Text'
import { useSelector } from 'react-redux'
import msg from '../../shared/utils/message'

const Message = ({ date, body }) => {
    if (!body) return
    return (
        <div
            className="d-flex align-items-center justify-content-start p-1 my-1 w-100 border-1 border-info border-opacity-25 border-top">
            <div className="date me-3">{date}</div>
            <div className="content">{body}</div>
        </div>
    )
}

const Logs = () => {
    const bottomRef = useRef(null)
    const logMeg = useSelector(state => state.appReducer.logMsg)
    const players = useSelector(state => state.gameReducer.players)

    useLayoutEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [logMeg])

    return (
        <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
            <div className="w-100 d-flex align-items-center justify-content-center fw-semibold"
                 style={{ height: '8%', color: '#ec9700' }}>
                <Text content={'Logs'}/>
            </div>
            <div className="w-100 text-light fw-normal font-monospace"
                 style={{ height: '92%', backgroundColor: '#0c233d' }}>
                <div className="d-flex flex-column align-items-start justify-content-start w-100 px-3 scroll-container">
                    <table style={{ width: '97%' }}>
                        <tbody>
                        {
                            logMeg.map((log, index) => {
                                let playerName = `Player ${log.initiator}`
                                players.map((player) => {
                                    if (player.position === log.initiator) {
                                        playerName = player.name
                                    }
                                })
                                return (
                                    <Message key={index} date={moment(log.time).format('hh:mm a')}
                                             body={msg(playerName, log.action)}/>
                                )
                            })
                        }

                        </tbody>
                    </table>
                    <div ref={bottomRef}></div>
                </div>
            </div>
        </div>
    )
}

export default Logs