export const storePlayer = (player) => {
    sessionStorage.setItem('player', JSON.stringify(player))
    localStorage.setItem('player', JSON.stringify(player))
}

export const getPlayer = () => {
    return sessionStorage.getItem('player')
}

export const clearPlayer = () => {
    sessionStorage.removeItem('player')
}

export const storeId = (id) => {
    sessionStorage.setItem('id', JSON.stringify(id))
}

export const getId = () => {
    return sessionStorage.getItem('id')
}

export const clearId = () => {
    sessionStorage.removeItem('id')
}