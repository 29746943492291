import React, { Component } from 'react'
import MicIcon from '../static/img/microphone-solid.svg'
import '../static/css/textfield.scss'

class TextField extends Component {
    state = {
        speech: ''
    }

    setSpeech(speech) {
        this.setState({ speech })
    }

    clearText() {
        this.setSpeech('')
    }

    render() {
        const { inputProps, onClick = (msg) => {}, ref } = this.props
        const { speech } = this.state
        return (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <div className="d-flex bg-light mx-2 w-100" style={{ height: '60%', borderRadius: '40px' }}>
                    <div className="d-flex justify-content-end align-items-center h-100"
                         style={{ width: '92%' }}>
                        <input {...inputProps} className="h-75 ms-3 p-3 shadow-none"
                               ref={ref}
                               value={speech}
                               onChange={(ev) => {this.setSpeech(ev.target.value)}}
                               style={{ width: '98%', fontSize: '20px', border: 'none', outline: 'none' }}/>
                    </div>
                    <div className="d-flex align-items-center justify-content-center h-100" style={{ width: '8%' }}
                         onClick={() => onClick(speech)}>
                        <img src={MicIcon} className="me-5 w-50 h-75" style={{ cursor: 'pointer' }} alt="mic"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TextField
