import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import '../static/css/frame.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setBetButtonDisabledState } from '../action'

export const Frame = React.memo(
    ({ playerId, children, color, chipsAmount = 0, betClickHandler = () => {} }) => {
        const currentPlayer = useSelector(state => state.gameReducer.player?.position || 1)
        const players = useSelector(state => state.gameReducer.players)
        let playerIndex = 0
        players.map((player, index) => {
            if (player.position === playerId) {
                playerIndex = index
            }
        })

        const chips = useSelector(state => state.gameReducer.players[playerIndex]?.chips || 0)
        const slotOpen = useSelector(state => state.gameReducer.players[playerIndex]?.slot || '')
        const playerName = useSelector(state => state.gameReducer.players[playerIndex]?.name)
        const betButtonDisabled = useSelector(state => state.appReducer.betButtonDisabled)

        const cards = useSelector(state => state.gameReducer?.players[playerIndex]?.cards || [])
        const _cards = cards.slice(cards?.length - 2)
        const cardsUrl1 = _cards[0] ? (`` || (playerId === currentPlayer ? `/cards/${_cards[0]}.svg` : `/cards/CB.png`)) : ``
        const cardsUrl2 = _cards[1] ? (`` || (playerId === currentPlayer ? `/cards/${_cards[1]}.svg` : `/cards/CB.png`)) : ``
        const styles1 = {
            'content': `url(${cardsUrl1})`,
            'borderColor': color
        }
        const styles2 = {
            'content': `url(${cardsUrl2})`,
            'borderColor': color
        }
        const [hoverStyle1, setHoverStyle1] = useState({})
        const [hoverStyle2, setHoverStyle2] = useState({})

        const dispatch = useDispatch()

        useEffect(() => {
            if (chips <= 0) {
                dispatch(setBetButtonDisabledState(true))
            }
        }, [chips])

        return (
            <div className={`frame frame-${playerId} user-select-none`}>
                <div className="top-bar">
                    <div className="tag d-flex align-items-center justify-content-around">
                        <span
                            className="me-2 text-warning"
                            style={{ fontSize: '0.9vw' }}>{chips ?? chipsAmount}</span>
                        <span className={clsx({
                            'text-light px-2 p-1 ms-2 user-select-none d-inline-flex rounded' : true,
                            'bg-info pointer': currentPlayer === playerId,
                            'bg-secondary opacity-75': currentPlayer !== playerId,
                            'not-allowed': betButtonDisabled || currentPlayer !== playerId,
                            'opacity-75': currentPlayer === playerId && betButtonDisabled
                        })}
                              onClick={() => {
                                  if (chips > 0) {
                                      betClickHandler()
                                  }
                              }}>Bet {process.env.REACT_APP_DISTRIBUTE_CHIPS_AMOUNT}</span>

                    </div>

                    <div className="cards-container px-1" style={{ backgroundColor: color }}>
                        <div className="card" style={hoverStyle1} onMouseEnter={() => {setHoverStyle1(styles1)}}
                             onMouseLeave={() => {setHoverStyle1({})}}><img
                            src={_cards[0] ? (playerId === currentPlayer ? `/cards_thumbnails/${_cards[0]}.svg` : `/cards/CB.png`) : ``}
                            alt=""/>
                        </div>
                        <div className="card" style={hoverStyle2} onMouseEnter={() => {setHoverStyle2(styles2)}}
                             onMouseLeave={() => {setHoverStyle2({})}}><img
                            src={_cards[1] ? (playerId === currentPlayer ? `/cards_thumbnails/${_cards[1]}.svg` : `/cards/CB.png`) : ``}
                            alt=""/>
                        </div>
                    </div>
                </div>
                <div
                    className="video-container"
                    style={{
                        border: `3px solid ${color}`
                    }}
                >
                    {children}
                </div>
                <div className="d-flex align-items-center justify-content-center bottom-bar fw-bolder">
                    <div className="d-flex align-items-center justify-content-center"
                         style={{
                             color: color
                         }}
                    >
                        {slotOpen === 'CLOSED' ? playerName : 'Waiting for player'}
                    </div>
                </div>
            </div>
        )
    }
)
