export const setDealCards = card => {
    return {
        type: 'DEAL_CARDS',
        payload: card
    }
}
export const setUserDetails = user => {
    return {
        type: 'SET_USER_DETAILS',
        payload: user
    }
}

export const setPlayersUpdate = players => {
    return {
        type: 'SET_PLAYERS_UPDATE',
        payload: players
    }
}

export const setCards = (cards = []) => {
    return {
        type: 'SET_CARDS',
        payload: cards
    }
}

export const setChips = (chips = 0) => {
    return {
        type: 'SET_CHIPS',
        payload: chips
    }
}

export const setMaximumPlayerExceeded = (state) => {
    return {
        type: 'SET_MAX_PLAYER_EXCEEDED',
        payload: state
    }
}

export const setChipsInPot = (state) => {
    return {
        type: 'SET_CHIPS_IN_POT',
        payload: state
    }
}

export const setWebsocketMeg = (msg) => {
    return {
        type : 'SET_WEB_SOCKET_MEG',
        payload : msg
    }
}

export class dealCards {
}