import typeEng from './lang/en'

const omittedEvents = [
    'REQUEST_POSITION',
    'LOG_UPDATED',
    'TEXT_TO_SPEECH',
    'PLAYERS_UPDATED',
    'RECONNECT_CLIENT',
    'RECONNECT_CLIENT_FAILED',
    'INSUFFICIENT_CARDS',
    'MAX_PLAYER_EXCEEDED']

const generateMessage = (player, type, lang = 'en') => {
    if (omittedEvents.includes(type)) return
    return `${player} ${getTypeMessage(type, lang)}`
}

const getTypeMessage = (type, lang = 'en') => {
    switch (lang) {
        case 'en':
            return typeEng[type]
        default:
            return typeEng[type]
    }
}

export default generateMessage