import React from 'react'
import { useSelector } from 'react-redux'
import Text from '../../shared/components/Text'
import '../static/css/playerselect.scss'
import Hexagon from '../../shared/static/img/hexagon.svg'

const highlighting = '5px solid #ffffff'

const PlayerSelect = ({ setPOV, disabled }) => {
    const selectedPlayer = useSelector(state => state.appReducer.selectedPlayer)
    const handleSelectedPlayer = (selectedPlayer) => {
        if (disabled) return
        setPOV(selectedPlayer)
    }
    return (
        <div className="w-100 h-100 d-flex flex-column justify-content-center">
            <div className="w-100 d-flex align-items-center justify-content-center fw-semibold"
                 style={{ height: '12%', color: '#ec9700' }}><Text content={'Choose Player'}/>
            </div>
            <div className="w-100 d-flex flex-column align-items-center justify-content-center p-2" style={{
                height: '88%',
                backgroundImage: `url(${Hexagon})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '40%',
                backgroundPosition: 'center',
                opacity: disabled && '50%'
            }}>
                <div className="d-flex w-100" style={{ height: '20%' }}>
                    <div className="h-100 w-25"></div>
                    <div className="h-100 w-25"></div>
                    <div className="h-100 w-25 mx-lg-4 d-flex justify-content-center align-items-center fw-semibold"
                         style={{
                             borderRadius: '50%',
                             cursor: disabled ? 'not-allowed' : 'pointer',
                             backgroundColor: '#ec9700',
                             border: selectedPlayer === 0 ? highlighting : 'none'
                         }} onClick={() => handleSelectedPlayer(0)}>All
                    </div>
                    <div className="h-100 w-25"></div>
                    <div className="h-100 w-25"></div>
                </div>
                <div className="d-flex w-100" style={{ height: '20%' }}>
                    <div
                        className="h-100 w-25 ms-lg-4 me-lg-0 d-flex justify-content-center align-items-center fw-semibold"
                        style={{
                            borderRadius: '50%',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                            backgroundColor: '#F0E7CF',
                            border: selectedPlayer === 4 ? highlighting : 'none'
                        }} onClick={() => handleSelectedPlayer(4)}>P5
                    </div>
                    <div className="d-flex h-100 w-75">
                    </div>
                    <div
                        className="h-100 w-25 ms-lg-0 me-lg-4 d-flex justify-content-center align-items-center fw-semibold"
                        style={{
                            borderRadius: '50%',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                            backgroundColor: '#881FF4',
                            border: selectedPlayer === 3 ? highlighting : 'none'
                        }}
                        onClick={() => handleSelectedPlayer(3)}>P1
                    </div>
                </div>
                <div className="d-flex w-100" style={{ height: '20%' }}>
                    <div className="h-100 w-25"></div>
                    <div className="d-flex align-items-center justify-content-center h-100 w-75 mx-4">
                    </div>
                    <div className="h-100 w-25"></div>
                </div>
                <div className="d-flex w-100" style={{ height: '20%' }}>
                    <div
                        className="h-100 w-25  ms-lg-4 me-lg-0 d-flex justify-content-center align-items-center fw-semibold"
                        style={{
                            borderRadius: '50%',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                            backgroundColor: '#3BC9D6',
                            border: selectedPlayer === 5 ? highlighting : 'none'
                        }} onClick={() => handleSelectedPlayer(5)}>P4
                    </div>
                    <div className="d-flex h-100 w-75">
                    </div>
                    <div
                        className="h-100 w-25 ms-lg-0 me-lg-4 d-flex justify-content-center align-items-center fw-semibold"
                        style={{
                            borderRadius: '50%',
                            cursor: disabled ? 'not-allowed' : 'pointer',
                            backgroundColor: '#84DE45',
                            border: selectedPlayer === 2 ? highlighting : 'none'
                        }} onClick={() => handleSelectedPlayer(2)}>P2
                    </div>
                </div>
                <div className="d-flex w-100" style={{ height: '20%' }}>
                    <div className="h-100 w-25"></div>
                    <div className="h-100 w-25"></div>
                    <div className="h-100 w-25 mx-lg-4 d-flex justify-content-center align-items-center fw-semibold"
                         style={{
                             borderRadius: '50%',
                             cursor: disabled ? 'not-allowed' : 'pointer',
                             backgroundColor: '#C33A32',
                             border: selectedPlayer === 1 ? highlighting : 'none'
                         }} onClick={() => handleSelectedPlayer(1)}>P3
                    </div>
                    <div className="h-100 w-25"></div>
                    <div className="h-100 w-25"></div>
                </div>
            </div>
        </div>
    )
}

export default PlayerSelect