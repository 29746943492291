import React from 'react'
import clsx from 'clsx'
// import '../static/css/button.scss'

const Button = ({ color, onClick, children, icon, textColor, width, height, style, disabled, ...props }) => {
    return (
        <button disabled={ disabled }
                onClick={ onClick }
                { ...props }
                className={ clsx({
                    'p-xxl-3 m-xxl-2 p-md-2 m-md-0 p-sm-0 m-sm-0 ludo-button d-inline-flex justify-content-center align-items-center': true,
                    prefix: !!icon,
                }) }
                style={ {
                    background: color,
                    color: textColor || '#000000',
                    width: style?.width || '90%',
                    height: style?.height,
                    fontWeight: style?.fontWeight || 500,
                    borderRadius: '40px',
                    cursor: disabled ? 'not-allowed' : style?.cursor || 'pointer',
                    border: 'none',
                    opacity: disabled ? '50%' : 'unset',
                } }
        >
            { icon && <span className="icon">
                          <img src={ icon } alt="button icon"/>
                      </span> }

            { children }
        </button>
    )
}

export default Button