import React, { Fragment, useEffect, useRef, useState } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { Unity } from 'react-unity-webgl'
import difference from 'lodash/difference'
import initJitsi from '../../script'
import { Frame } from './Frame'
import '../static/css/unity.scss'
import { getSearchParams } from '../../shared/utils/url-util'

const config = {
    positions: [3, 2, 1, 4, 5],
    colors: ['#C33A32', '#84DE45', '#881FF4', '#F0E7CF', '#3BC9D6'],
    chips: [0, 0, 0, 0, 0],
    dealers: ['John', 'Jane'],
    avatarIds: [1, 2],
    phrases: {
        dealerChange: 'Dealer change requested, Fond farewell',
        dealerGreeting: 'Warm greeting',
        newGame: 'ready to play',
    },
}

const jitsiUrl = process.env.REACT_APP_JITSI_SERVER
// const jitsiStunUrl = process.env.REACT_APP_JITSI_STUN_SERVER

// const jitsiUrl = 'https://jaas.8x8.vc/#/'
// const jitsiUrl = 'meet.jit.si'
const defaultRoomName = 'room-2'

const jitsiConfig = {
    disableAudioLevels: false,
    disableSimulcast: false,
    enableFirefoxSimulcast: false,
    startWithVideoMuted: false,
    startVideoMuted: false,
    openBridgeChannel: 'websocket',
    testing: {
        p2pTestMode: false,
    },
    enableNoAudioDetection: true,
    enableNoisyMicDetection: true,
    channelLastN: -1,
    useStunTurn: true,
    p2p: {
        enabled: true,
        // Use XEP-0215 to fetch STUN and TURN servers.
        useStunTurn: true,
        // The STUN servers that will be used in the peer to peer connections
        stunServers: [
            // { urls: 'stun:game.sayon.cloud:3478' },
            { urls: 'stun:meet-jit-si-turnrelay.jitsi.net:443' },
        ],
    },
    analytics: {},
    deploymentInfo: {
        // shard: "shard1",
        // region: "europe",
        // userRegion: "asia"
    },
}

const localStreamConfig = {
    devices: ['audio', 'video'],
    resolution: '426x240',
    // cameraDeviceId: videoInputDevice.id || 'none',
    // micDeviceId: audioInputDevice.id || 'none',
}

let activeRoom

export const UnityScene = ({ unityProvider, betClickHandler, setVideo }) => {
    const players = useSelector(state => state.gameReducer.players)
    const maximumPlayerExceeded = useSelector(state => state.gameReducer.maximumPlayerExceeded)
    const currentPlayer = useSelector(state => state.gameReducer?.player)
    const player = useSelector(state => state.gameReducer.player)
    const videos = useSelector(state => state.videoReducer.videos)
    const [tracks, setTracks] = useState({})
    const [remoteTrack, setRemoteTrack] = useState()
    const [user, setUser] = useState({ name: 'saai' })

    const otherPlayers = players.filter((el) => el?.position !== player?.position)

    const videoRef = useRef()

    useEffect(() => {
        initJitsi(() => {
            setTimeout(() => {
                init()
            }, 5000)
        })

        return () => {
            if (activeRoom) {
                activeRoom.leave()
            }
        }
    }, [])

    const init = () => {
        window.JitsiMeetJS.init()

        const connection = new window.JitsiMeetJS.JitsiConnection(
            null,
            null,
            {
                hosts: {
                    domain: jitsiUrl,
                    muc: `conference.${jitsiUrl}`,
                },
                serviceUrl: `wss://${jitsiUrl}/xmpp-websocket`
            }
        )

        connection.addEventListener(
            window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            () => onConnectionSuccess(connection)
        )

        connection.connect()
    }

    const onConnectionSuccess = (connection) => {

        try {
            const roomname = getSearchParams('room') || defaultRoomName // not used
            activeRoom = connection.initJitsiConference(roomname, {})

            activeRoom.on(window.JitsiMeetJS.events.conference.TRACK_ADDED, onRoomTrackAdded)

            window.JitsiMeetJS.createLocalTracks(localStreamConfig)
                .then(tracks => {
                    const videoTrack = tracks.find(track => track.type === 'video')
                    activeRoom.addTrack(videoTrack)
                })
                .catch(err => {
                    console.error('[log] jitsi local track creation failed')
                })

            activeRoom.join()
        } catch (error) {
            console.error('[log] jitsi room creation failed', error)
        }
    }

    const onRoomTrackAdded = (track) => {
        const participantId = track.getParticipantId()

        if (track.isLocal()) {
            const req = {
                type: 'SET_VIDEO',
                position: currentPlayer.position,
                participantId
            }

            setVideo(req)

            track.attach(videoRef.current)

            return
        }

        setTracks((prev) => {
            return {
                ...prev,
                [participantId]: track,
            }
        })
    }

    const allPositionsActiveUser = difference(
        config.positions.filter((el) => el !== currentPlayer?.position),
    )

    const pointOfView = useSelector(state => state.gameReducer.player?.position || 1)

    if (maximumPlayerExceeded) {
        return <div
            className="d-flex align-items-center justify-content-center w-100 h-100 bg-dark-blue text-light-blue">
            <h1 className="text-center fw-bolder m-5">Maximum Player Exceeded</h1>
        </div>
    }

    return (
        <div className={`scene pov-${pointOfView}`}>
            {
                pointOfView &&
                <>
                    <Frame
                        playerId={currentPlayer?.position}
                        color={currentPlayer?.color}
                        amount={currentPlayer?.amount}
                        name={currentPlayer?.name}
                        cards={currentPlayer?.cards}
                        chipsAmount={currentPlayer?.chips}
                        betClickHandler={betClickHandler}
                    >
                        <video muted autoPlay playsInline ref={videoRef} width={500} height={500}/>
                    </Frame>

                    {
                        otherPlayers
                            .map((player) => {
                                const participantId = get(videos, [player.position, 'participantId'])
                                const track = tracks[participantId]

                                return (
                                    <Frame
                                        playerId={player.position}
                                        color={player.color}
                                        amount={player.amount}
                                        name={player.name}
                                        cards={player.cards}
                                        chipsAmount={player.chips}
                                        betClickHandler={betClickHandler}
                                    >
                                        {
                                            track &&
                                            <RenderVideo track={track} position={player.position}/>
                                        }
                                    </Frame>
                                )
                            })
                    }
                </>
            }

            <Unity
                className="unity w-100 h-100"
                unityProvider={unityProvider}
                style={{ position: 'absolute' }}/>
        </div>
    )
}

const RenderVideo = ({ track = {}, position }) => {
    const ref = useRef()

    useEffect(() => {
        if (!track) {
            return
        }

        track.attach(ref.current)

        return () => track.detach(ref.current)
    }, [track])

    return (
        <video muted autoPlay playsInline ref={ref} width={500} height={500}/>
    )
}
