export function initJitsi (callback) {

    const jqExists = document.getElementById('jquery')
    const jitsiExists = document.getElementById('jitsi')

    let retryTimer
    retryTimer = setInterval(() => {
        if (window.JitsiMeetJS && window.jQuery) {
            clearInterval(retryTimer)
            callback()
        }
    }, 500)

    if (!jqExists) {
        const script = document.createElement('script')
        script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js'
        // script.defer = true;
        script.type = 'text/javascript'
        script.id = 'jquery'
        document.head.appendChild(script)

        script.onerror = () => {
            console.error('log loading jquery failed')
            if (retryTimer) clearInterval(retryTimer)
        }
    }

    if (!jitsiExists) {
        const script = document.createElement('script')
        script.src = 'https://meet.jit.si/libs/lib-jitsi-meet.min.js'
        // script.defer = true;
        script.type = 'text/javascript'
        script.id = 'jitsi'
        document.head.appendChild(script)

        script.onerror = () => {
            console.error('log loading jitsi failed')
            if (retryTimer) clearInterval(retryTimer)
        }
    }

    if (jitsiExists && jqExists && callback) {
        callback()
    }
}

export default initJitsi
