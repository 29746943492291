import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../shared/static/img/logo.png'
import { clearId, clearPlayer } from '../../shared/utils/stroge-util'
import ExitIcon from '../../shared/static/svg/exit.svg'
import SpeakerIcon from '../../shared/static/svg/volume-high-solid.svg'
import MutedSpeakerIcon from '../../shared/static/svg/volume-xmark-solid.svg'
import ReloadIcon from '../../shared/static/svg/arrow-rotate-right-solid.svg'
import NewGameIcon from '../../shared/static/svg/plus-solid.svg'
import { setMutedState } from '../action'
import '../static/css/navbar.scss'

const Divider = () => <div className="border border-right border-secondary mx-5 h-40px"/>
const Navbar = ({
    leaveGame = () => {},
    newGame = () => {}
}) => {
    const speakerMutedState = useSelector(state => state.appReducer.speakerMutedState)
    const dispatch = useDispatch()
    const toggleAudio = () => {
        dispatch(setMutedState(!speakerMutedState))
    }
    const clearSiteData = () => {
        localStorage.clear()
        sessionStorage.clear()
        indexedDB.deleteDatabase('UnityCache')
        window.location.reload()
    }
    return (
        <div className="w-100 bg-faded-blue nav-height d-flex flex-column justify-content-center">
            <div className="col d-flex justify-content-between align-items-center mx-2">
                <div className="container-fluid align-items-center d-flex">
                    <img src={logo} alt="Palatar Logo" className="ml-25px" style={{ cursor: 'pointer' }}
                         onClick={() => window.location.href = '/'}/>

                    <Divider/>

                    <span className="text-light-blue fw-semibold fs-30px" onClick={() => window.location.href = '/'}
                          style={{ cursor: 'pointer' }}>CARD DEALER</span>
                </div>
                <div
                    className="d-flex justify-content-center align-items-center exit bg-info user-select-none mx-1"
                    onClick={() => {
                        clearSiteData()
                    }}
                >
                    <img src={ReloadIcon} alt="Clear Site Data" title="Clear site data and Refresh"/>
                </div>
                <div
                    className="d-flex justify-content-center align-items-center exit bg-success user-select-none mx-1"
                    style={{backgroundColor: '#3BC9D6'}}
                    onClick={() => {
                        newGame()
                    }}
                >
                    <img src={NewGameIcon} alt="New Game" title="New Game"/>
                </div>

                <div
                    className="d-flex justify-content-center align-items-center exit bg-warning user-select-none mx-1"
                    onClick={() => {
                        toggleAudio()
                    }}
                >
                    <img src={speakerMutedState ? MutedSpeakerIcon : SpeakerIcon} alt="Speaker"
                         title="Mute / Unmute Audio"/>
                </div>
                <div
                    className="d-flex justify-content-center align-items-center exit bg-danger user-select-none mx-1"
                    onClick={() => {
                        leaveGame()
                        clearId()
                        clearPlayer()
                        window.location.href = process.env.REACT_APP_REDIRECT_URL
                    }}
                >
                    <img src={ExitIcon} alt="Exit"
                         title={`Exit from Palatar Cards and Redirect to ${process.env.REACT_APP_REDIRECT_URL}`}/>
                </div>
            </div>

        </div>
    )
}

export default Navbar