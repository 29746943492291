const initialState = {
    cards: [],
    player: {},
    players: [],
    chips: 0,
    pointOfView: 1,
    maximumPlayerExceeded: false,
    chipsInPot: 0,
    webSocketMeg : {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DEAL_CARDS':
            return { ...state, cards: action.payload }

        case 'SET_USER_DETAILS' :
            return { ...state, player: action.payload }

        case 'JOIN_GAME' :
            return { ...state, player: action.payload }

        case 'BUY_CHIPS' :
            return { ...state, chips: action.payload }

        case 'SET_POINT_OF_VIEW' :
            return { ...state, pointOfView: action.payload }

        case 'SET_PLAYERS_UPDATE' :
            return { ...state, players: action.payload }

        case 'SET_CARDS' :
            return { ...state, cards: action.payload }

        case 'SET_CHIPS' :
            return { ...state, chips: action.payload }

        case 'SET_MAX_PLAYER_EXCEEDED':
            return { ...state, maximumPlayerExceeded: action.payload }

        case 'SET_CHIPS_IN_POT':
            return { ...state, chipsInPot: state.chipsInPot + action.payload }

        case 'SET_WEB_SOCKET_MEG' :
            return { ...state, webSocketMeg: action.payload }

        default:
            return state
    }
}
export default reducer