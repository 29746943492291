export const setButtonDisabledState = (state = false) => ({
    type: 'SET_BUTTON_DISABLED_STATE',
    payload: state
})

export const setResetButtonDisabledState = (state = false) => ({
    type: 'SET_RESET_BUTTON_DISABLED_STATE',
    payload: state
})

export const setBetButtonDisabledState = (state = false) => ({
    type: 'SET_BET_BUTTON_DISABLED_STATE',
    payload: state
})

export const setNextRoundButtonDisabledState = (state = false) => ({
    type: 'SET_NEXT_ROUND_BUTTON_DISABLED_STATE',
    payload: state
})

export const setChipsToWinnerButtonDisabledState = (state = false) => ({
    type: 'SET_CHISP_TO_WINNER_BUTTON_DISABLED_STATE',
    payload: state
})

export const setSelectedPlayerState = (state = 0) => ({
    type: 'SET_SELECTED_PLAYER_STATE',
    payload: state
})

export const setUpdateLog = (log) => ({
    type: 'SET_UPDATED_LOG',
    payload: log
})

export const clearLog = (logs) => ({
    type: 'CLEAR_LOG',
    payload: logs
})
export const setMutedState = (state = true) => ({
    type: 'SET_MUTED_STATE',
    payload: state
})

export const setSceneLoadedState = (state = false) => ({
    type: 'SET_SCENE_LOADED_STATE',
    payload: state
})